<template>
    <UikTopBar
        class="navigation"
        :class="{
            'navigation--side': sideNav,
            'navigation--collapsed': menuCollapsed,
            'navigation--open': openNav
        }">
        <div class="uik-top-bar__container">
            <button
                class="uik-top-bar__open-navigation-btn"
                :class="{'uik-top-bar__open-navigation-btn--close': openNav}"
                @click="openNav = !openNav"
            >
                <div v-for="n in 3" :key="n"/>
            </button>

            <UikTopBarSection class="top-bar__logo">
                <router-link to="/dashboard">
                    <img src="/img/COMPETO_LOGO_2024.svg" alt="Competo Logo"/>
                    <span>PORTAL</span>
                </router-link>
                <button
                    class="menu__toggleNav"
                    @click="toggleMenuCollapse"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
                <button
                    class="menu__openNav"
                    @click="openNav = !openNav"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
            </UikTopBarSection>

            <UikTopBarSection>
                <UikTopBarLinkContainer
                    v-if="$route.name !== 'User Info'"
                    class="uik-top-bar__navigation"
                    :class="{'uik-top-bar__navigation--open': openNav}"
                >
                    <UikTopBarLink  router-link="/dashboard" @click.native="openNav = false">
                        <UikIcon :icon="['fad', 'user']"/>
                        <span>{{ $t('Global.navigation.dashboard') }}</span>
                    </UikTopBarLink>
                    <UikTopBarLink v-if="!companyEmployee" router-link="/jobs" @click.native="openNav = false">
                        <!-- <UikIcon :icon="['fad', 'sack-dollar']"/> -->
                        <UikIcon :icon="['fad', 'briefcase']"/>
                        <span>{{ $t('Global.navigation.jobs') }}</span>
                    </UikTopBarLink>
                    <UikTopBarLink router-link="/questionnaires" @click.native="openNav = false">
                        <UikIcon :icon="['fad', 'clipboard-list']"/>
                        <span>{{ $t('Global.navigation.questionnaires') }}</span>
                    </UikTopBarLink>
                    <UikTopBarLink router-link="/consents" @click.native="openNav = false">
                        <UikIcon :icon="['fad', 'shield-check']"/>
                        <span>{{ $t('Global.navigation.consents') }}</span>
                    </UikTopBarLink>
                    <UikTopBarLink router-link="/settings" @click.native="openNav = false">
                        <UikIcon :icon="['far', 'cog']"/>
                        <span>{{ $t('Global.navigation.settings') }}</span>
                    </UikTopBarLink>
                </UikTopBarLinkContainer>

                <UikDivider vertical margin/>

                <div class="uik-top-bar__avatar-container">
                    <UikAvatar
                        class="uik-avatar--top-bar"
                        :name="user.name"
                        :textBottom="user.position_title"
                        :imageUrl="user.avatar_url ? user.avatar_url : ''"
                        color="primary"
                        @click.native="dropdownSettings = true"
                    />
                    <UikDropdown v-model="dropdownSettings">
                        <UikDropdownItem @click.native="dropdownSettings=false; openNav = false; $api.Auth.logout()">
                            <UikIcon :icon="['fal', 'sign-out-alt']" style="margin-right: 8px;"/>
                            {{ $t('Global.navigation.logout') }}
                        </UikDropdownItem>
                    </UikDropdown>
                </div>
            </UikTopBarSection>
        </div>
        <UikOverlay
            class="navigation__openNav-overlay"
            :visible="openNav"
            @close="openNav = false"
        />
    </UikTopBar>
</template>

<script>
export default {
  data () {
    return {
      openNav: false,
      menuCollapsed: false,
      dropdownSettings: false,
      sideNav: false
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    userPermissions () {
      if (!this.user.permissions) return []
      return this.user.permissions
    },
    companyEmployee () {
      if (this.$api.Auth.user.companyemployee) {
        return this.$api.Auth.user.companyemployee
      } else return null
    }
  },
  methods: {
    iconStyle (path, exact) {
      let style = "far"
      if (
        (exact && this.$route.path === path) ||
        (!exact && this.$route.path.startsWith(path))
      ) {
        style = "fas"
      }
      return style
    },
    hasPermission (index) {
      return true
      // @todo Implement

      // if (Object.keys(this.userPermissions).length > 0) {
      //   return this.userPermissions.indexOf("view/" + index) >= 0
      // }
      // return false
    },
    toggleMenuCollapse () {
      this.menuCollapsed = !this.menuCollapsed
      localStorage.menuCollapsed = this.menuCollapsed
    }
  }
}
</script>
