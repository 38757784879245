import store from "@/providers/store"
/**
 * Route guard
 * We will set guards here, for checking if
 * the user has the right access level for
 * entering a specific route
 */

async function initStateHelper () {
  try {
    const response = await store.state.init
    return response
  } catch (e) {

  }
}

export async function ifAuthenticated (to, from, next) {
  /**
   * Not sure why this works, but it does.
   * Without "store.state.init" auth user is not
   * redirected from login page (if you go there after login)
   */
  await initStateHelper()

  let user = store.getters["Auth/getUserDetails"]

  if ((Object.keys(user).length > 0 && !user.employee && !user.companyemployee) && ((Object.keys(user).length > 0 && !user.name) || (Object.keys(user).length > 0 && user.candidate && (!user.candidate.gender || !user.candidate.education || !user.candidate.birthday || !user.candidate.career))) && !to.path.startsWith("/user-info")) {
    next("/user-info")
    return
  }

  if (store.getters["Auth/hasUncheckedConsents"] && store.getters["Auth/hasUncheckedConsents"].uid && !to.path.startsWith("/consents/unsigned/") && !to.path.startsWith("/user-info")) {
    next("/consents/unsigned/" + store.getters["Auth/hasUncheckedConsents"].uid)
    return
  }

  if (store.getters["Auth/isAuthenticated"]) {
    /**
     * When unauthenticated user tries to apply for a job, it redirects him to login and saves job details to localstorage
     * Once he logins, he will be redirected to job he tried to apply to
     * (before he is redirected, he might be redirected to userInfo and uncheckedConsents)
     */
    if (localStorage.getItem("jobdetails") && !to.path.startsWith("/consents/unsigned/") && !to.path.startsWith("/user-info")) {
      let params = JSON.parse(localStorage.getItem("jobdetails"))
      localStorage.removeItem("jobdetails")

      const now = new Date()

      if (params && now.getTime() < params.expires) {
        next("/jobs/" + params.uid)
        return
      }
    }

    next()
    return
  }

  /**
   * Allow unauthenticated users to enter jobs
   */
  if (to.path.startsWith("/jobs")) {
    next()
    return
  }

  next("/login")
}

export async function ifNotAuthenticated (to, from, next) {
  await initStateHelper()

  if ((to.path.startsWith("/login") || to.path.startsWith("/registration") || to.path.startsWith("/consent/confirm")) && store.getters["Auth/isAuthenticated"]) {
    next("/")
    return
  }

  if (!store.getters["Auth/isAuthenticated"]) {
    next()
    return
  }
  next(document.location.pathname)
}
