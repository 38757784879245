export default {
  registration: {
    headline: "Registracija",
    headline_desc: "Za registracijo računa spodaj vnesite svoje podatke.",
    back_to_login: "Nazaj na prijavo",
    register: "Registriraj",
    view: "Ogled",
    close: "Zapri",
    agreement: "To polje je obvezno.",
    description: {
      basic: "Osnovni podatki",
      current_employment: "Podatki o trenutni zaposlitvi",
      wanted_employment: "Podatki o želeni zaposlitvi"
    },
    loading_cv: "Procesiranje CVja...",
    loading_cv_description: "Podatki iz CVja lahko izpolnijo prazna polja v obrazcu.",
    fields: {
      name: {
        label: "Ime in priimek"
      },
      gender: {
        label: "Spol"
      },
      education: {
        label: "Stopnja izobrazbe"
      },
      birthday: {
        label: "Datum rojstva",
        info: "Datum rojstva potrebujemo za obdelavo in normiranje anonimiziranih podatkov."
      },
      career: {
        label: "Trenutni karierni nivo"
      },
      email: {
        label: "Elektronski naslov"
      },
      password: {
        label: "Geslo"
      },
      confirm_password: {
        label: "Ponovi geslo"
      },
      cv: {
        label: "CV",
        description: "Življenjepisa niste pripeli. Za prijavo na delovno mesto je to ključen dokument."
      },
      linkedin_url: {
        label: "LinkedIn povezava"
      },
      employer: {
        label: "Trenutni delodajalec"
      },
      industries: {
        label: "Industrija"
      },
      workfields: {
        label: "Področje dela"
      },
      expectations: {
        label: "Želena letna bruto plača (EUR)"
      },
      region: {
        label: "Regija"
      },
      phone: {
        label: "Telefonska številka"
      },
      position_title: {
        label: "Naziv delovnega mesta"
      },
      address: {
        label: "Ulica in hišna številka"
      },
      city: {
        label: "Mesto"
      },
      postal: {
        label: "Poštna številka"
      },
      country: {
        label: "Država"
      },
      education_summary: {
        label: "Dosežen naziv in izobraževalna ustanova"
      },
      notice_days: {
        label: "Odpovedni rok (št. dnevov)"
      }
    },
    gender: {
      male: "Moški",
      female: "Ženska"
    },
    password_rule: "Geslo mora vsebovati najmanj 6 znakov,<br/> vsaj eno veliko črko, malo črko, številko in poseben znak",
    confirm_error: "Potrditveno geslo se ne ujema z geslom.",
    error: "Med postopkom registracije je prišlo do napake"
  },
  registration_success: {
    title_success: "Registracija uspešna",
    thank_you_note: "Zahvaljujemo se za vpis v bazo iskalcev zaposlitve.",
    confirmation: "Za uspešno registracijo potrebujemo vašo potrditev, ki ste jo prejeli po elektronski pošti."
  },
  activation: {
    headline: "Aktiviranje računa",
    headline_desc: "Vaš račun se aktivira. Kmalu boste preusmerjeni..."
  },
  activation_success: {
    headline: "Račun aktiviran",
    headline_desc: "Vaš račun je bil aktiviran! Lahko nadaljujete s prijavo.",
    to_login: "Na prijavo"
  },
  activation_error: {
    headline: "Napaka pri aktivaciji računa",
    headline_desc: "Pri aktiviranju vašega računa je prišlo do težave.",
    error_msg: "Sporočilo o napaki:",
    contact: "Za več informacij kontaktirajte administratorja."
  }
}
