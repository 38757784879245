import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/questionnaires",
    name: "Questionnaires",
    meta: { title: "Vprašalniki" },
    component: () => import(/* webpackChunkName: "Questionnaires List" */ "@/Modules/Questionnaire/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/questionnaires/:id",
    name: "Questionnaire",
    meta: { title: "Vprašalnik" },
    component: () => import(/* webpackChunkName: "Questionnaire Details" */ "@/Modules/Questionnaire/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/public/questionnaires/:id",
    name: "Public Questionnaire",
    meta: { title: "Vprašalnik" },
    component: () => import(/* webpackChunkName: "Questionnaire Details" */ "@/Modules/Questionnaire/Views/Details/Details"),
    children: [
      {
        path: "complete",
        name: "Public Questionnaire",
        meta: { title: "Vprašalnik" }
      }
    ]
  },
  {
    path: "/public/questionnaires/360/:id",
    name: "Public Questionnaire 360",
    meta: { title: "Vprašalnik" },
    component: () => import(/* webpackChunkName: "Questionnaire Details" */ "@/Modules/Questionnaire/Views/Details/Details"),
    children: [
      {
        path: "complete",
        name: "Public Questionnaire 360",
        meta: { title: "Vprašalnik" }
      }
    ]
  },
  {
    path: "/public/questionnaires/rosl/:id",
    name: "Public Questionnaire ROSL",
    meta: { title: "Vprašalnik" },
    component: () => import(/* webpackChunkName: "Questionnaire Details" */ "@/Modules/Questionnaire/Views/Details/Details"),
    children: [
      {
        path: "complete",
        name: "Public Questionnaire ROSL",
        meta: { title: "Vprašalnik" }
      }
    ]
  }
]
