export default {
  views: {
    list: {
      view_button: "View consent",
      empty_list_notice: "Your consent list is empty."
    },
    details: {
      header1: "Consent",
      header2: "If you want to continue we need your consent",
      back_button: "Back to the list of consents",
      confirm_button: "Confirm consent"
    },
    confirmation: {
      headline: "Consent confirmation",
      headline_desc: "Consent is being confirmed. You will be redirected shortly..."
    },
    confirmation_success: {
      headline: "Consent confirmed",
      headline_desc: "Thank you for confirming consent.",
      to_login: "To login"
    },
    confirmation_error: {
      headline: "Consent confirmation error",
      headline_desc: "There was an issue with confirming consent.",
      error_msg: "Error message:",
      contact: "Please contact the administrator for more information."
    }
  }
}
