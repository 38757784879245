export default {
  views: {
    list: {
      view_button: "View job",
      id: "Job ID",
      publish_date: "Published on",
      location: "Location",
      worktime: "Type",
      region: "Region",
      applied: "Applied",
      search_placeholder: "Search by name and location ...",
      no_info: "No information",
      all_regions: "All regions",
      all_worktimes: "All types",
      empty_list_notice: "The job list is empty. Change your search criteria or check back later."
    },
    details: {
      back_button: "List",
      id: "Job ID",
      publish_date: "Published on",
      location: "Location",
      worktime: "Type",
      region: "Region",
      contact_person: "Contact person",
      contact_email: "E-mail address",
      apply: "Apply",
      applied: "Applied",
      close: "Close",
      headlines: {
        offerings: "What is company offering?",
        expectations: "Desired skills, experience and competences",
        company: "Company introduction",
        description: "Key challenges",
        about_us: "About us"
      },
      about_us: "We are a consulting company that deals with search and selection, HR consulting and people development. We believe that matching candidate with the company culture is extremely important for satisfaction on both sides. We are aware that a resume alone is not enough, so at candidates we look for that real »spark« - only those who do their work with passion can push the boundaries and create successful stories. At Competo, we are looking for just such: talents who will co-create the successes and ventures of our partners.",
      apply_popup: {
        title: "Apply",
        cancel: "Cancel",
        confirm: "Confirm",
        message: "To apply for a job, you must be logged in to a user account. We will redirect you to the home page where you can sign in or create a new account."
      },
      application_confirmation_popup: {
        title: "Application successful!",
        message: "Thank you for your application.",
        close: "Close"
      }
    }
  }
}
