export default {
  registration: {
    headline: "Registration",
    headline_desc: "Enter your details below to register your Competo account.",
    back_to_login: "Back to login",
    register: "Register",
    view: "View",
    close: "Close",
    agreement: "This field is required.",
    description: {
      basic: "Basic information",
      current_employment: "Current employment data",
      wanted_employment: "Desired employment info"
    },
    loading_cv: "Processing CV...",
    loading_cv_description: "Data from CV may fill in empty fields in the form.",
    fields: {
      name: {
        label: "Name and last name"
      },
      gender: {
        label: "Sex"
      },
      education: {
        label: "Level of education"
      },
      birthday: {
        label: "Date of Birth",
        info: "Date of birth is needed for the processing and normalization of anonymized data."
      },
      career: {
        label: "Current career level"
      },
      email: {
        label: "E-mail"
      },
      password: {
        label: "Password"
      },
      confirm_password: {
        label: "Confirm password"
      },
      cv: {
        label: "CV",
        description: "You have not attached your CV. This is an essential document for applying for a job."
      },
      linkedin_url: {
        label: "LinkedIn link"
      },
      employer: {
        label: "Current employer"
      },
      industries: {
        label: "Industry"
      },
      workfields: {
        label: "Field of work"
      },
      expectations: {
        label: "Desired annual gross salary (EUR)"
      },
      region: {
        label: "Region"
      },
      phone: {
        label: "Phone"
      },
      position_title: {
        label: "Job title"
      },
      address: {
        label: "Street and house number"
      },
      city: {
        label: "City"
      },
      postal: {
        label: "Postal address"
      },
      country: {
        label: "Country"
      },
      education_summary: {
        label: "Title and educational institution achieved"
      },
      notice_days: {
        label: "Notice period (days)"
      }
    },
    gender: {
      male: "Male",
      female: "Female"
    },
    password_rule: "Password has to be at least 6 characters long <br/> and contain at least one uppercase letter, lowercase letter, number and special character",
    confirm_error: "The password confirmation does not match.",
    error: "An error occured during registration process"
  },
  registration_success: {
    title_success: "Registration successful",
    thank_you_note: "Thank you for registering in the database of job seekers.",
    confirmation: "For a successful registration, we need your confirmation, which you received by email."
  },
  activation: {
    headline: "Activating account",
    headline_desc: "Your account is being activated. You will be redirected shortly..."
  },
  activation_success: {
    headline: "Account activated",
    headline_desc: "Your account has been activated! You can proceed to login.",
    to_login: "To login"
  },
  activation_error: {
    headline: "Account activation error",
    headline_desc: "There was an issue with activating your account.",
    error_msg: "Error message:",
    contact: "Please contact the administrator for more information."
  }
}
