export default {
  views: {
    details: {
      candidate_settings: {
        headline: {
          basic: "Personal data",
          other: "Other data"
        },
        headline_description: "User data",
        headline_description_2: "Other data linked to user",
        description: {
          basic: "Basic information",
          current_employment: "Current employment data",
          wanted_employment: "Desired employment info"
        },
        fields: {
          name: {
            label: "Name and last name"
          },
          gender: {
            label: "Sex"
          },
          education: {
            label: "Level of education"
          },
          birthday: {
            label: "Date of Birth"
          },
          career: {
            label: "Current career level"
          },
          email: {
            label: "E-mail",
            placeholder: "info@email.com"
          },
          address: {
            label: "Street and house number"
          },
          postal: {
            label: "Postal address"
          },
          city: {
            label: "City"
          },
          country: {
            label: "Country"
          },
          phone: {
            label: "Phone"
          },
          position_title: {
            label: "Job title"
          },
          position_department: {
            label: "Department"
          },
          username: {
            label: "Username"
          },
          linkedin_url: {
            label: "LinkedIn link"
          },
          employer: {
            label: "Current employer"
          },
          industries: {
            label: "Industry"
          },
          workfields: {
            label: "Field of work"
          },
          expectations: {
            label: "Desired annual gross salary (EUR)"
          },
          region: {
            label: "Region"
          },
          phone_candidate: {
            label: "Phone"
          },
          position_title_candidate: {
            label: "Job title"
          },
          education_summary: {
            label: "Title and educational institution achieved"
          },
          notice_days: {
            label: "Notice period (days)"
          }
        },
        gender: {
          male: "Male",
          female: "Female"
        },
        button: {
          save: "Save data"
        }
      },
      reset_password: {
        title: "Update password",
        subtitle: "Security and login",
        form: {
          currentPassword: "Current password",
          newPassword: "New password",
          changePassword: "Change password",
          passwordRule: "Password must contain at least 6 characters, at least one uppercase letter, lowercase letter, number and special character"
        },
        feedback: {
          succcess: "Your password is reset!",
          changeItAgain: "Reset again?"
        }
      },
      language: {
        title: "Change language"
      },
      tests: {
        headline: "Testings",
        headline_description: "List of your testings. You have not yet participated in a test.",
        table: {
          title: "Title",
          responsible: "Responsible person",
          updated_at: "Updated"
        }
      },
      projects: {
        headline: "Projects",
        headline_description: "List of your projects. You have not yet participated in a project.",
        table: {
          title: "Title",
          responsible: "Responsible person",
          updated_at: "Updated"
        }
      }
    },
    user_info: {
      headline: "Welcome to Competo Portal",
      headline_description: "Your profile is almost ready",
      p1: "We have created your user account, we only need a few more details.",
      p2: "Before proceeding please enter missing data and check if all entered data is correct.",
      p3: "You can always change your entries in your user settings."
    }
  }
}
